import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = {
  key: 0,
  class: "h-full max-w-screen-xl mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlitLampWithoutLenses = _resolveComponent("SlitLampWithoutLenses")!
  const _component_InstructionModal = _resolveComponent("InstructionModal")!
  const _component_SmartFormSection = _resolveComponent("SmartFormSection")!
  const _component_VARefraction = _resolveComponent("VARefraction")!
  const _component_LensCollectionConfirmation = _resolveComponent("LensCollectionConfirmation")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_LensOrdering = _resolveComponent("LensOrdering")!
  const _component_LensOrderingConfirmation = _resolveComponent("LensOrderingConfirmation")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.name,
    steps: _ctx.steps,
    loading: _ctx.loading,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    status: _ctx.status,
    onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('exit')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        loading: _ctx.loading,
        type: "button",
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.back()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      (_ctx.step < _ctx.steps.length - 1)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "large",
            class: "ml-2",
            "right-icon": "arrow-right",
            loading: _ctx.loading,
            onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.next()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            "right-icon": "arrow-right",
            size: "large",
            color: "success",
            class: "ml-2",
            loading: _ctx.loading,
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.complete()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.encounter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.step === 0)
                ? (_openBlock(), _createBlock(_component_SlitLampWithoutLenses, _mergeProps({ key: 0 }, _ctx.encounterData?.preFittingSlitLamp, {
                    config: _ctx.slitLampConfig,
                    errors: _ctx.errors,
                    loading: _ctx.loading,
                    laterality: _ctx.laterality,
                    "is-pre-fitting": "",
                    "onUpdate:conditions": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', {
            pre_fitting_slit_lamp_conditions: $event
          }))),
                    "onUpdate:isNoneChecked": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', {
            pre_fitting_is_slit_lamp_condition_none: $event
          }))),
                    "onUpdate:details": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', {
            pre_fitting_slit_lamp_details: $event
          })))
                  }), null, 16, ["config", "errors", "loading", "laterality"]))
                : _createCommentVNode("", true),
              (_ctx.step === 1)
                ? (_openBlock(), _createBlock(_component_InstructionModal, {
                    key: 1,
                    body: _ctx.$t('custom.menicon.encounter.lens-collection-instructions.body'),
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["body", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === 2)
                ? (_openBlock(), _createBlock(_component_VARefraction, {
                    key: 2,
                    loading: _ctx.loading,
                    title: _ctx.$t('custom.menicon.encounter.va-over-refraction.title'),
                    "va-label": _ctx.$t('platform.eyes.va-with-lenses'),
                    "va-description": "",
                    data: _ctx.encounterData.vaRefraction,
                    errors: _ctx.errors,
                    "required-lateralities": _ctx.lateralities,
                    "va-unit": _ctx.encounter.va_unit,
                    onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, {
                    bottom: _withCtx(() => [
                      (_ctx.contactLensDiameterSection)
                        ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                            key: 0,
                            class: "pt-6 pb-6 lg:pb-10",
                            level: 1,
                            "form-submission-id": _ctx.formSubmission.id,
                            "patient-id": _ctx.patientId,
                            section: _ctx.contactLensDiameterSection,
                            next: _ctx.next,
                            back: _ctx.back
                          }, null, 8, ["form-submission-id", "patient-id", "section", "next", "back"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["loading", "title", "va-label", "data", "errors", "required-lateralities", "va-unit"]))
                : _createCommentVNode("", true),
              (_ctx.step === 3)
                ? (_openBlock(), _createBlock(_component_InstructionModal, {
                    key: 3,
                    body: _ctx.$t('custom.menicon.encounter.lens-collection-instructions.remove'),
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["body", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === 4)
                ? (_openBlock(), _createBlock(_component_SlitLampWithoutLenses, _mergeProps({ key: 4 }, _ctx.encounterData?.slitLamp, {
                    config: _ctx.slitLampConfig,
                    errors: _ctx.errors,
                    loading: _ctx.loading,
                    laterality: _ctx.laterality,
                    "onUpdate:conditions": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_conditions: $event
          }))),
                    "onUpdate:isNoneChecked": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update', {
            is_slit_lamp_condition_none: $event
          }))),
                    "onUpdate:details": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_details: $event
          })))
                  }), null, 16, ["config", "errors", "loading", "laterality"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-collection-confirmation.name')))
                ? (_openBlock(), _createBlock(_component_LensCollectionConfirmation, {
                    key: 5,
                    "encounter-id": _ctx.encounterId,
                    "patient-id": _ctx.patientId,
                    lateralities: _ctx.lateralities,
                    "current-prescriptions": _ctx.currentPrescriptions
                  }, null, 8, ["encounter-id", "patient-id", "lateralities", "current-prescriptions"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering.name')))
                ? (_openBlock(), _createBlock(_component_LensOrdering, _mergeProps({
                    key: 6,
                    loading: _ctx.loading,
                    errors: _ctx.errors,
                    laterality: _ctx.laterality,
                    "patient-id": _ctx.patientId,
                    "journey-id": _ctx.journeyId,
                    reorder: ""
                  }, _ctx.lensOrderingData, {
                    "current-prescriptions": _ctx.currentPrescriptions,
                    "journey-type": _ctx.journeyType,
                    data: _ctx.prescriptionData,
                    "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePrescriptionData($event)))
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_MkBadge, {
                        block: "",
                        stroked: "",
                        color: "alert",
                        class: "mb-4",
                        size: "large"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.encounter.lens-ordering.diameter-warning')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 16, ["loading", "errors", "laterality", "patient-id", "journey-id", "current-prescriptions", "journey-type", "data"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.lens-ordering-confirmation.name')) && _ctx.prescriptionData)
                ? (_openBlock(), _createBlock(_component_LensOrderingConfirmation, {
                    key: 7,
                    loading: _ctx.loading,
                    data: _ctx.prescriptionData,
                    "show-message": _ctx.showConfirmationWarningMessage
                  }, null, 8, ["loading", "data", "show-message"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.showConfirmationModal)
        ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
            key: 0,
            heading: _ctx.smartFormCompleteProps.heading,
            body: _ctx.smartFormCompleteProps.body,
            "is-glyph": "",
            "icon-color": "success"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('backToPrescription')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.back-to-patient')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonLink, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.exit-to-list')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name", "steps", "loading", "current-step", "patient", "status"]))
}