
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission, Patient, Prescription } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete, SmartFormSection } from '@/lib/components';
import VARefraction from '@/custom/menicon/views/encounter/VARefraction.vue';
import LensOrdering from '@/custom/menicon/views/encounter/LensOrdering.vue';
import LensOrderingConfirmation from '@/custom/menicon/views/encounter/LensOrderingConfirmation.vue';
import { ILensOrderingAnswer } from '@/custom/menicon';
import InstructionModal from '@/custom/menicon/views/encounter/InstructionModal.vue';
import LensCollectionConfirmation from '@/custom/menicon/views/encounter/LensCollectionConfirmation.vue';
import { EncounterData } from '@/helpers/encounter.helper';
import { countErrors, validate } from '@/helpers/validators.helper';
import { Laterality } from '@/custom/menicon/models';
import { SavingStatus } from '@/lib/constants';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';
import { useSmartFormStore } from '@/stores/smartForm.store';

@Options({
  components: {
    VARefraction,
    SmartFormSection,
    WorkflowLayout,
    LensOrdering,
    LensOrderingConfirmation,
    LensCollectionConfirmation,
    BaseButton,
    ButtonLink,
    InstructionModal,
    SmartFormComplete,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    encounterData: {
      type: Object,
      default: null
    },
    encounter: {
      type: Object,
      default: null
    },
    formSubmission: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    currentPrescriptions: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    },
    journeyType: {
      type: String,
      required: true
    },
    directOrder: {
      type: Boolean,
      default: true
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string;
  patientId!: string;
  patient!: Patient;
  initialEncounter!: EncounterData | null;
  formSubmission!: FormSubmission | null;
  encounterData!: EncounterData | null;
  encounter!: Encounter;
  directOrder!: boolean;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  prescriptionData: ILensOrderingAnswer | null = null;
  contactLensDiameterSectionId = 'cc39ea2b-0359-4f3f-9e0a-c8d9701f7b2e';
  currentPrescriptions!: {
    [Laterality.right]: Prescription | null;
    [Laterality.left]: Prescription | null;
  };

  laterality!: Laterality | null;
  smartForm = useSmartFormStore();

  get steps(): string[] {
    return [
      this.$t('custom.menicon.encounter.slit-lamp.pre-fitting-name') as string,
      '',
      this.$t('custom.menicon.encounter.va-over-refraction.name') as string,
      '',
      this.$t('custom.menicon.encounter.slit-lamp.name') as string,
      ...(this.newOrder
        ? [
            this.$t('custom.menicon.encounter.lens-ordering.name') as string,
            this.$t('custom.menicon.encounter.lens-ordering-confirmation.name') as string
        ]
        : [this.$t('custom.menicon.encounter.lens-collection-confirmation.name') as string])
    ];
  }

  get newOrder(): boolean {
    return !!(
      this.formSubmission &&
      this.smartForm.getAnswer(this.formSubmission.id, 'dc39ea2b-0359-4f3f-9e0a-c8d9701f7b2a') === true &&
      this.smartForm.getAnswer(this.formSubmission.id, '9b9e7d70-02ab-45d4-b516-117e544fba83') === true
    );
  }

  get smartFormCompleteProps() {
    if (this.newOrder) {
      if (this.directOrder) {
        return {
          heading: this.$t('custom.menicon.prescriptions.order-placed-heading') as string,
          body: this.$t('custom.menicon.prescriptions.order-placed-body') as string
        };
      }
      return {
        heading: this.$t('custom.menicon.prescriptions.consult-complete-heading') as string,
        body: this.$t('custom.menicon.prescriptions.consult-complete-body') as string
      };
    }

    return {
      heading: this.$t('custom.menicon.encounter.lens-collection-confirmation.completion.heading') as string,
      body: this.$t('custom.menicon.encounter.lens-collection-confirmation.completion.body') as string
    };
  }

  get showConfirmationWarningMessage(): boolean {
    return this.diameterOfLensAnswer === true;
  }

  get diameterOfLensAnswer() {
    return (
      this.formSubmission &&
      this.smartForm.getAnswer(this.formSubmission.id, 'dc39ea2b-0359-4f3f-9e0a-c8d9701f7b2a')
    );
  }

  get step(): number {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  get contactLensDiameterSection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.contactLensDiameterSectionId
    );
  }

  get currentSectionId(): string | null {
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.va-over-refraction.name') as string)) {
      return this.contactLensDiameterSectionId;
    }
    return null;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  get lensOrderingData() {
    return this.initialEncounter?.lensOrdering;
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  complete() {
    this.$emit('complete', {
      sectionId: this.currentSectionId,
      order: (this.newOrder && this.prescriptionData) || null
    });
  }

  validateCurrentStep() {
    let errors = {};
    if (this.step === 2 && this.rules?.vaRefraction) {
      errors = validate(this.rules.vaRefraction, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.lens-ordering.name') as string) &&
      this.prescriptionData
    ) {
      errors = this.prescriptionData.errors;
    }
    if (this.step === 0 && this.rules?.preFittingSlitLamp) {
      errors = validate(this.rules.preFittingSlitLamp, this.encounter);
    }
    if (this.step === 4 && this.rules?.slitLamp) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    this.$emit('setErrors', errors);
  }

  updatePrescriptionData(data: ILensOrderingAnswer) {
    this.prescriptionData = data;
    if (this.errorCount) {
      this.validateCurrentStep();
    }
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }

  back() {
    this.$emit('back');
  }
}
